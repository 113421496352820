import { useEffect, useState } from "react";
import {
  Body1,
  Card,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import thumbsUp from "../../../../assets/images/dt-mood/awesome-thumbs-up.svg";
import { ChangeMiDia } from "../../store/mi-dia/MiDiaSlice";
import useApi from "../../../../hooks/useApi";
import styles from "./MiDia.module.css";

const MiDia = (props) => {
  const { reloadMiDia } = props;
  const [interactionsLogsList, setInteractionsLogsList] = useState();
  const isUpdatedMiDia = useSelector((state) => state.miDia.isUpdatedMiDia);
  const dispatch = useDispatch();
  const { data, loading, error, getData } = useApi();
  const SkeletonArray = [1, 2, 3, 4, 5];
  let userSession = useSelector((state) => state.authentication.userSession);
  const userId = userSession ? userSession.userId : "";
  const apiUrl = `/interactions-logs/list/?userId=${userId}&limit=10`;

  useEffect(() => {
    getData(apiUrl);
  }, []);

  useEffect(() => {
    if (reloadMiDia) getData(apiUrl);
  }, [reloadMiDia]);

  useEffect(() => {
    if (data) {
      setInteractionsLogsList(data);
      dispatch(ChangeMiDia(true));
    }
    if (error) {
      setInteractionsLogsList([]);
    }
  }, [data, error]);

  useEffect(() => {
    if (!isUpdatedMiDia) {
      getData(apiUrl);
    }
  }, [isUpdatedMiDia]);

  return (
    <Card className="w-100 p-0">
      <div className="p-3 border-bottom mb-0">
        <div className="container text-start">
          <div className="row align-items-center">
            <div className="col">
              <img
                src={thumbsUp}
                alt="icono-thumbs-up"
                className="align-self-center astronaut-icon"
              />
              <span className="align-self-end ps-2 pt-1">Mi día DTmood</span>
            </div>
          </div>
        </div>
      </div>
      <Body1 className={`overflow-auto p-3 ${styles.height400}`}>
        <Row>
          <Col sm="12">
            <p className="font-14 text-color-dark">Mis últimas interacciones</p>
          </Col>
        </Row>
        <Row className="p-1 ps-0">
          <Col sm="12">
            {loading && (
              <ul className="w-100">
                {SkeletonArray.map((item, index) => {
                  return (
                    <li className={`pt-2 ${styles.liMiDia}`} key={index}>
                      <h5 className="text-blue-dtmood">
                        <Skeleton>
                          <SkeletonItem size={16} className="my-2" />
                        </Skeleton>
                      </h5>
                      <Card className={`ms-2 ${styles.timelineCard}`}>
                        <Body1>
                          <Skeleton>
                            <SkeletonItem size={16} className="my-2" />
                          </Skeleton>
                        </Body1>
                      </Card>
                    </li>
                  );
                })}
              </ul>
            )}
            {interactionsLogsList &&
              interactionsLogsList.data.length > 0 &&
              !loading && (
                <ul className="w-100">
                  {interactionsLogsList.data.map((item, index) => {
                    return (
                      <li className={`pt-2 ${styles.liMiDia}`} key={index}>
                        <h5 className="text-blue-dtmood">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </h5>
                        <Card className={`ms-2 ${styles.timelineCard}`}>
                          <Body1>
                            <p className="font-14 font-medium text-color-dark m-0">
                              {item.interactionType}
                            </p>
                            <p className="font-14 text-color-dark m-0">
                              {item.data}
                            </p>
                          </Body1>
                        </Card>
                      </li>
                    );
                  })}
                </ul>
              )}
          </Col>
        </Row>
      </Body1>
    </Card>
  );
};

export default MiDia;
