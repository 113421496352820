import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Col, Row, Container } from "reactstrap";
import { useDispatch } from "react-redux";
import MicrosoftLogin from "react-microsoft-login";

import logoDtMood from "../../../../assets/images/dt-mood/logo-dtmood.png";
import style from "./Login.module.css";
import {
  getDtMoodSession,
  getUser,
  login,
  saveDtMoodSession,
} from "../../services";
import { ChangeAuthentication } from "../../store/authentication/AuthenticationSlice";
const url = `${process.env.REACT_APP_API_DTMOOD}/auth/microsoft/login`;
// const MICROSOFT_CLIENT_ID = process.env.MICROSOFT_CLIENT_ID;
const MICROSOFT_CLIENT_ID = "fef6fc30-1f77-4a25-8e52-56e94a20f091";
const redirectUri = process.env.REACT_APP_TAB_ENDPOINT;

const Login = (props) => {
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(ChangeAuthentication(getDtMoodSession()));
    }
  }, [data]);

  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  const authHandler = async (err, data) => {
    if (err) {
      setLoginError(true);
    }
    if (data) {
      postData(data.account.username, data.accessToken);
    }
  };

  const postData = async (username, token) => {
    const sessionData = await login(token)
      .then((res) => {
        setData(res);
        saveDtMoodSession(res.accessToken, res.timeToExpire);
        return res;
      })
      .catch((error) => {
        setError(error);
        return undefined;
      });

    if (!sessionData) {
      setLoginError(true);
      return;
    }

    getUser(username)
      .then((res) => {
        saveDtMoodSession(
          sessionData.accessToken,
          sessionData.timeToExpire,
          res.group.id,
          res.id,
          res.username,
          res.client.id,
          res.email,
          res.firstName,
          res.lastName
        );

        dispatch(ChangeAuthentication(getDtMoodSession()));
      })
      .catch((error) => {
        return undefined;
      });
  };

  return (
    <>
      <Container fluid className="m-0 p-0">
        <Row lg="8" xl="8" xxl="8" className={`m-0 ${style.rowDiv}`}>
          <Col xs="12" sm="12" md="6" lg="4" xl="4" xxl="4">
            <div
              className={`d-flex justify-content-center align-items-center m-auto ${style.mw380}`}
            >
              <div className="w-100 ms-3 me-3 p-4">
                <div className="logo mb-4 text-center">
                  <span className="db">
                    <img
                      className={`${style.logoLoginDtmood}`}
                      src={logoDtMood}
                      alt="logo"
                    />
                  </span>
                </div>

                <h3 className="text-blue-dtmood text-center">
                  ¡Es genial tenerte aquí!
                </h3>
                <p className="mt-3 mb-4 text-center">
                  Por favor, inicia sesión en DTmood
                </p>

                <Form>
                  <FormGroup className="text-center mt-4">
                    <MicrosoftLogin
                      clientId={MICROSOFT_CLIENT_ID}
                      authCallback={authHandler}
                      redirectUri={redirectUri}
                    />
                  </FormGroup>
                  <FormGroup className="text-center mt-1">
                    <Link to="/auth/password-reset" relative="path">
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </FormGroup>
                </Form>
                <p className={`text-center mt-5 mt-sm-5 mt-md-0 ${style.fz10}`}>
                  Powered by Darien Technology, S.A.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
