import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Dialog,
  DialogSurface,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogBody,
  Button,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

import styles from "./ContactPointsCard.module.css";

import iconoContactPoints from "../../../../../assets/images/dt-mood/iconos/puntos-de-contacto.svg";
import {
  showContactPointsSuccessAlert,
  showContactPointsWarningAlert,
} from "../../../utilities";
import ContactPointsStepper from "../contact-points-stepper/ContactPointsStepper";
import { ContactPointsContext } from "../../../context";
import { ChangeAwardStatus } from "../../../store/award/AwardSlice";
import { ChangePuntosAcumulados } from "../../../store/puntos-acumulados/PuntosAcumuladosSlice";

const ContactPointsCard = (props) => {
  const [contactPointsState, setContactPointsState] = useState();
  const [open, setOpen] = useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(false);

    if (contactPointsState && contactPointsState.state === "award") {
      dispatch(ChangeAwardStatus(contactPointsState.badge_id));
      dispatch(ChangePuntosAcumulados(false));
      setContactPointsState();
    }

    if (contactPointsState && contactPointsState.state === "success") {
      showContactPointsSuccessAlert();
      setContactPointsState();
      dispatch(ChangePuntosAcumulados(false));
    }

    if (contactPointsState && contactPointsState.state === "warning") {
      showContactPointsWarningAlert();
      setContactPointsState();
    }
  }, [contactPointsState]);

  return (
    <>
      <Card
        className={`w-100 align-items-center cursor-pointer border-0 p-0`}
        onClick={() => setOpen(true)}
      >
        <div
          className={`d-flex flex-row w-100 p-3 ${styles.evaluacionesBg} ${styles.br6}`}
        >
          <div className="w-100 d-flex">
            <img
              src={iconoContactPoints}
              alt="iconoNoticiasEventos"
              className={`icon-card-dtmood align-self-center ${styles.icono}`}
            />
            <span className="ms-2 align-self-center text-color-headings">
              Feedbacks
            </span>
          </div>
        </div>
      </Card>

      <Dialog
        open={open}
        onOpenChange={(event, data) => {
          setOpen(data.open);
        }}
        onDismiss={{ ...restoreFocusTargetAttribute }}
      >
        <DialogSurface className={`p-1 ${styles.evaluacionesBg}`}>
          {open && (
            <>
              <DialogBody className={`${styles.dialogBody}`}>
                <div className={`p-3 ${styles.titleDiv}`}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <img
                      src={iconoContactPoints}
                      alt="icono-contact-point"
                      className={`align-self-center me-2 pt-2 ${styles.icono}`}
                    />
                    <span className="text-color-headings font-medium">
                      Feedbacks
                    </span>
                  </DialogTitle>
                </div>

                <DialogContent className={`p-3 ${styles.dialogContent}`}>
                  <ContactPointsContext.Provider
                    value={{ setContactPointsState }}
                  >
                    <ContactPointsStepper />
                  </ContactPointsContext.Provider>
                </DialogContent>
              </DialogBody>
            </>
          )}
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default ContactPointsCard;
