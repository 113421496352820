import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogSurface,
  DialogContent,
  DialogTrigger,
  DialogBody,
  DialogTitle,
  Button,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular, ArrowRight24Regular } from "@fluentui/react-icons";

import style from "./MySurveys.module.css";
import useApi from "../../../../hooks/useApi";
import { FormularioEncuesta } from "../../components";
import { ChangeAwardStatus } from "../../store/award/AwardSlice";
import { ChangeMiDia } from "../../store/mi-dia/MiDiaSlice";
import { EncuestaContext } from "../../context";
import { useTokenDtMood } from "../../../../hooks/useTokenDTMood";
import iconoSurveys from "..//../../../assets/images/dt-mood/iconos/my-surveys.png";
import {
  showFeedbackSuccessAlert,
  showFeedbackWarningAlert,
} from "../../utilities";

const MySurveys = () => {
  const { data: surveysList, getData } = useApi();
  const [survey, setSurvey] = useState();
  const { data: interactionData, putData: putInteraction } = useApi();
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const [encuestaState, setEncuestaState] = useState();
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const dispatch = useDispatch();
  const { validarTokenDtMood } = useTokenDtMood();
  const [awardModal, setAwardModal] = useState(false);
  const [wonAward, setWonAward] = useState();

  let userSession = useSelector((state) => state.authentication.userSession);
  const url = `${process.env.REACT_APP_API_DTMOOD}/survey-results/list?userId=${userSession.userId}`;

  useEffect(() => {
    validarTokenDtMood();
    getData(url);
  }, []);

  useEffect(() => {
    if (selected) {
      setOpen(true);
      sendUserInteraction();
    }
  }, [selected]);

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }

    if (interactionData && interactionData.award.id !== "" && !open) {
      dispatch(ChangeAwardStatus(true));
      dispatch(ChangeMiDia(false));
    }
  }, [interactionData, open]);

  const sendUserInteraction = () => {
    const username = userSession ? userSession.username : "";
    const interactionUrl = `${process.env.REACT_APP_API_DTMOOD}/api/news/${username}/`;
    putInteraction(interactionUrl);
  };

  useEffect(() => {
    setOpen(false);

    if (encuestaState && encuestaState.state === "award") {
      setAwardModal(true);
      setWonAward(encuestaState.badge_id);
      setEncuestaState();
    }

    if (encuestaState && encuestaState.state === "success") {
      showFeedbackSuccessAlert(encuestaState.id);
      setEncuestaState();
      getData(url);
    }

    if (encuestaState && encuestaState.state === "warning") {
      showFeedbackWarningAlert();
      setEncuestaState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encuestaState]);

  const getCardColor = (type) => {
    switch (type) {
      case "ONE_TIME":
        return "#2cccd3";

      case "RECURRING":
        return "#655DC5";

      case "CONDITIONAL":
        return "#FFC833";

      default:
        return "#2cccd3";
    }
  };

  return (
    <>
      <div className="w-100 m-1 border-top">
        <Row className="mt-1 pt-4 mb-4">
          <Card className="w-100">
            <CardTitle className="p-3 border-bottom mb-0">
              <div className="container text-start">
                <div className="row align-items-center">
                  <div className="col">
                    <img
                      src={iconoSurveys}
                      alt="iconoSurveys"
                      className="align-self-center astronaut-icon"
                    />
                    <span className="align-self-center ps-2">
                      Mis encuestas activas
                    </span>
                  </div>
                </div>
              </div>
            </CardTitle>
            <CardBody>
              <h5 className={`${style.activasText} mb-3`}>
                Dedica un momento para responder tus encuestas activas:
              </h5>
              <Row className={`${style.equalHeightRow}`}>
                {surveysList &&
                  surveysList.data.length > 0 &&
                  surveysList.data
                    .filter((item) => item.status === false)
                    .map((item, index) => {
                      return (
                        <Col
                          xs="12"
                          md="6"
                          lg="4"
                          className="mb-2 d-flex cursor-pointer"
                          key={index}
                          onClick={() => {
                            setSurvey(item);
                            setOpen(true);
                          }}
                        >
                          <div
                            className={`${style.activasCard} w-100`}
                            style={{
                              backgroundColor: getCardColor(item.survey.type),
                            }}
                          >
                            <div className={`${style.activasCardIconDiv}`}>
                              <ArrowRight24Regular />
                            </div>
                            <div className={`${style.activasCardTitle} mt-2`}>
                              {item?.survey.title}
                            </div>
                            <div className={`${style.activasCardText}`}>
                              {item?.survey.description}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row className="mb-4">
          <Card className="w-100">
            <CardTitle className="p-3 border-bottom mb-0">
              <div className="container text-start">
                <div className="row align-items-center">
                  <div className="col">
                    <img
                      src={iconoSurveys}
                      alt="iconoSurveys"
                      className="align-self-center astronaut-icon"
                    />
                    <span className="align-self-center ps-2">
                      Mis encuestas respondidas
                    </span>
                  </div>
                </div>
              </div>
            </CardTitle>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Título</th>
                    <th>Fecha</th>
                    <th>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {surveysList &&
                    surveysList.data.length > 0 &&
                    surveysList.data
                      .filter((item) => item.status === true)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{item?.survey.id}</th>
                            <td>{item?.survey.title}</td>
                            <td>
                              {new Date(
                                item?.survey.createdAt
                              ).toLocaleDateString()}
                            </td>
                            <td>{item?.status ? "Completada" : "Pendiente"}</td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Row>
      </div>

      {survey && (
        <Dialog
          open={open}
          onOpenChange={(event, data) => {
            setOpen(data.open);
          }}
          onDismiss={{ ...restoreFocusTargetAttribute }}
        >
          <DialogSurface className={`p-1 ${style.feedbackBg}`}>
            <DialogBody className={`${style.dialogBody}`}>
              <div className={`p-3 ${style.titleDiv}`}>
                <DialogTitle
                  action={
                    <DialogTrigger action="close">
                      <Button
                        appearance="subtle"
                        aria-label="close"
                        icon={<Dismiss24Regular />}
                      />
                    </DialogTrigger>
                  }
                >
                  <img
                    src={iconoSurveys}
                    alt="icono-contact-point"
                    className={`align-self-center me-2 pt-2 ${style.icono}`}
                  />
                  <span className="text-color-headings font-medium">
                    Quick feedback
                  </span>
                </DialogTitle>
              </div>

              <DialogContent className={`p-3 mt-1 ${style.dialogContent}`}>
                <EncuestaContext.Provider value={{ setEncuestaState }}>
                  <FormularioEncuesta survey={survey} />
                </EncuestaContext.Provider>
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
};

export default MySurveys;
