import axios from "axios";
import { getDtMoodSession } from "./dtmood-session.service";

const apiDtMood = axios.create({
  baseURL: process.env.REACT_APP_API_DTMOOD,
});

const login = (token) => {
  const headers = {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return new Promise((resolve, reject) => {
    apiDtMood
      .post(`/auth/microsoft/login`, {}, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUser = (email) => {
  const dtMoodSession = getDtMoodSession();

  const headers = {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${dtMoodSession ? dtMoodSession.key : undefined}`,
  };

  return new Promise((resolve, reject) => {
    apiDtMood
      .get(`/users?email=${email}`, {
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { login, getUser };
