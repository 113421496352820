const saveDtMoodSession = (
  key,
  timeExpire,
  groupId = "",
  userId = "",
  username = "",
  clientId = "",
  email = "",
  first_name = "",
  last_name = ""
) => {
  const today = new Date();
  const fechaExpireDtMood = today.setSeconds(today.getSeconds() + timeExpire);

  const dtmoodSession = {
    key,
    time_expire: fechaExpireDtMood,
    groupId,
    userId,
    username,
    clientId,
    email,
    first_name,
    last_name,
  };
  window.localStorage.setItem("dtmood_session", JSON.stringify(dtmoodSession));
};

const getDtMoodSession = () => {
  const dtmoodSession = window.localStorage.getItem("dtmood_session");
  return dtmoodSession ? JSON.parse(dtmoodSession) : null;
};

const removeDtMoodSession = () => {
  return window.localStorage.removeItem("dtmood_session");
};

export { saveDtMoodSession, getDtMoodSession, removeDtMoodSession };
