import axios from "axios";
import { getDtMoodSession, removeDtMoodSession } from "../../auth/services";

const apiDtMood = axios.create({
  baseURL: process.env.REACT_APP_API_DTMOOD,
});

apiDtMood.interceptors.request.use(
  function (config) {
    const dtMoodSession = getDtMoodSession();
    if (!dtMoodSession) {
      return Promise.reject(new Error("user unauthenticated"));
    }
    const token = dtMoodSession ? dtMoodSession.key : undefined;

    config.headers = {
      "Content-Type": "application/json",
      accept: "application/json",
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiDtMood.interceptors.response.use(
  (response) => response,
  (error) => {
    const httpError = JSON.parse(JSON.stringify(error));

    if (httpError.status === 401) {
      removeDtMoodSession();
    }
  }
);

export default apiDtMood;
