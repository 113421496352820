import { useEffect, useState } from "react";
import {
  Button,
  Textarea,
} from "@fluentui/react-components";

import { Form, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";

const SeleccionMotivos = (props) => {
  const { setStep, motivosSeleccionados, setMotivosSeleccionados, setModal } =
    props;
  const [dynamicRequired, setDynamicRequired] = useState(true);
  const apiUrl = `/api/reasons/`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    reValidateMode: "onChange",
  });

  const watchComments = watch("comments");

  useEffect(() => {
    const val = !(watchComments && watchComments.trim() !== "");
    setDynamicRequired(val);

    watch((value, { name, type }) => console.log(value, name, type));
  }, [watch]);

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (motivosSeleccionados) {
      setValue('comments', motivosSeleccionados.comments);
      setValid(true);
    }
  }, []);

  const onSubmit = (data) => {
    setMotivosSeleccionados({
      comments: data.comments,
    });
    setStep(2);
  };

  return (
    <>
      <div className="flex-fill">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 2
        </p>

        <p className="font-16 font-medium text-center mb-3 mx-2 text-dark-dtmood">
        Escribe un comentario
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-1">
            <FormGroup className="w-100">
              <Textarea
                name="comments"
                id="comments"
                cols="30"
                rows="4"
                placeholder="Puedes escribir un comentario"
                {...register("comments", { maxLength: 300 })}
                className="mb-1 w-100"
              />
            </FormGroup>
            <div className="text-danger mt-0">
              {errors.comments &&
                errors.comments.type === "maxLength" &&
                "Máximo 300 caracteres"}
            </div>
          </div>

          <FormGroup className="text-center mt-4 mx-1">
            <div className="d-flex align-items-center justify-content-end">
              <Button
                appearance="primary"
                outline
                type="button"
                className="me-2"
                onClick={() => setModal(false)}
              >
                Regresar
              </Button>
              <Button appearance="primary" type="submit">
                Siguiente
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default SeleccionMotivos;
