import { useEffect, useState, useContext } from "react";
import { Button, Body1, Card } from "@fluentui/react-components";
import { AddCircleRegular, SubtractCircleFilled } from "@fluentui/react-icons";
import { Form, FormGroup, CardBody, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import useApi from "../../../../../hooks/useApi";
import styles from "./FormularioEncuesta.module.css";
import { getDtMoodSession } from "../../../../auth/services";
import { EncuestaContext } from "../../../context/EncuestaContext";
import { useDispatch } from "react-redux";
import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";

import FeelingsInput from "../feelings-input/FeelingsInput";
import RatingInput from "../rating-input/RatingInput";

const FormularioEncuesta = (props) => {
  const { survey } = props;
  const { data, getData } = useApi();
  const [detalleEncuesta, setDetalleEncuesta] = useState();
  const { loading: loadingPost, postData, data: dataPost } = useApi();
  const { setEncuestaState } = useContext(EncuestaContext);
  const detalleEncuestaUrl = `/survey-questions/survey/${survey.survey.id}`;
  const { error: errorPost } = useApi();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm({
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getData(detalleEncuestaUrl);
  }, []);

  useEffect(() => {
    if (data) {
      const dataNew = data.data.map((item) => {
        item.showInput = false;
        return item;
      });
      setDetalleEncuesta(dataNew);
    }
  }, [data]);

  useEffect(() => {
    watch((value, { name, type }) => {});
  }, [watch]);

  useEffect(() => {
    if (dataPost) {
      if (dataPost.entity.badgeAchieved) {
        setEncuestaState({
          state: "award",
          badge_id: dataPost.entity.badgeAchieved.id,
        });
      } else {
        setEncuestaState({
          state: "success",
        });
      }
      dispatch(ChangeMiDia(false));
    }

    if (errorPost) {
      console.log(errorPost);
      setEncuestaState({
        state: "warning",
      });
    }
  }, [dataPost, errorPost]);

  const onSubmit = (data) => {
    if (Object.keys(data).length === 0) return;
    const formData = formatData(data);
    postData(`/survey-answers`, formData);
  };

  const formatData = (data) => {
    const dtMoodSession = getDtMoodSession();
    const surveyAnswers = [];

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const surveyQuestionId = parseInt(key);
        let answers = [];

        if (Array.isArray(data[key])) {
          answers = data[key];
        } else {
          answers = data[key];
        }

        surveyAnswers.push({
          surveyQuestionId: surveyQuestionId,
          answers: answers,
        });
      }
    }

    return {
      surveyResultId: survey.id,
      userId: dtMoodSession.userId,
      surveyAnswers: surveyAnswers,
    };
  };

  const toggleForm = (index) => {
    const data = [...detalleEncuesta];
    data[index].showInput = !data[index].showInput;
    setDetalleEncuesta(data);
  };

  return (
    <>
      <div className="ps-3 pe-3">
        <p className="font-16 text-center mb-1 ms-2 me-2 mt-4 text-gray-dtmood">
          Valoramos mucho tu opinión
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 mt-2 text-dark-dtmood">
          ¡Ayúdanos respondiendo <br /> estas preguntas rápidas!
        </p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="w-100">
            {detalleEncuesta &&
              detalleEncuesta.map((item, index) => {
                return (
                  <>
                    <Card
                      className="w-100 mb-2 cursor-pointer"
                      onClick={() => toggleForm(index)}
                    >
                      <Body1 className="py-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="font-16 font-medium text-gray-dtmood d-flex">
                            <div className={`${styles.titleNumber}`}>
                              {index + 1}
                            </div>
                            <div>{item.question}</div>
                          </div>
                          <span className={styles.cardIcon}>
                            {!item.showInput && (
                              <AddCircleRegular className={styles.icon} />
                            )}
                            {item.showInput && (
                              <SubtractCircleFilled className={styles.icon} />
                            )}
                          </span>
                        </div>
                      </Body1>
                    </Card>
                    {item.showInput && item.type === "SHORT_TEXT" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center">
                              <input
                                name={item.id}
                                id={item.id}
                                type="text"
                                placeholder="Escribe tu respuesta"
                                {...register(`${item.id}`, {
                                  required: "Este campo es requerido",
                                  maxLength: 300,
                                })}
                                className={`border-0 w-100 ${styles.textArea}`}
                              />
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "LONG_TEXT" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center">
                              <textarea
                                name={item.id}
                                id={item.id}
                                cols="30"
                                rows="3"
                                placeholder="Escribe tu respuesta"
                                {...register(`${item.id}`, {
                                  required: "Este campo es requerido",
                                  maxLength: 300,
                                })}
                                className={`border-0 w-100 ${styles.textArea}`}
                              />
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "NUMERIC" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center">
                              <input
                                name={item.id}
                                id={item.id}
                                type="number"
                                {...register(`${item.id}`, {
                                  required: "Este campo es requerido",
                                })}
                                className={`border-0 w-100 ${styles.textArea}`}
                              />
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "SINGLE_OPTION" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center">
                              <Row>
                                {item.options &&
                                  item.options.map((item2, index2) => {
                                    return (
                                      <Col sm="12" key={index2}>
                                        <label>
                                          <input
                                            type="radio"
                                            value={item2}
                                            {...register(`${item.id}`, {
                                              required:
                                                "Debes seleccionar una opción",
                                            })}
                                          />
                                          <span className="ps-1">{item2}</span>
                                        </label>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "MULTIPLE_OPTIONS" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center">
                              <Row>
                                {item.options &&
                                  item.options.map((item2, index2) => {
                                    return (
                                      <Col sm="12" key={index2}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            value={item2}
                                            {...register(`${item.id}`, {
                                              required:
                                                "Debes seleccionar al menos una opción",
                                              validate: (value) =>
                                                value.length > 0 ||
                                                "Debes seleccionar al menos una opción",
                                            })}
                                          />
                                          <span className="ps-1">{item2}</span>
                                        </label>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "RATING" && (
                      <>
                        <Card className="w-100 mb-1">
                          <CardBody className="ps-2 pb-2">
                            <div className="d-flex align-items-center justify-content-center">
                              <label className="cursor-pointer">
                                <input
                                  type="number"
                                  name={item.id}
                                  id={item.id}
                                  className={styles.hidden}
                                  {...register(`${item.id}`, {
                                    required: "Debes seleccionar un valor",
                                  })}
                                />
                                <RatingInput
                                  onChange={(value) => {
                                    document.getElementById(item.id).value =
                                      value;
                                    setValue(`${item.id}`, value);
                                    trigger(`${item.id}`);
                                  }}
                                />
                              </label>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                    {item.showInput && item.type === "FEELINGS" && (
                      <>
                        <Card className="w-100 mb-2">
                          <CardBody className="ps-2">
                            <div className="d-flex align-items-center justify-content-center">
                              <label className="cursor-pointer">
                                <input
                                  type="number"
                                  name={item.id}
                                  id={item.id}
                                  className={styles.hidden}
                                  {...register(`${item.id}`, {
                                    required: "Debes seleccionar un icono",
                                  })}
                                />
                                <FeelingsInput
                                  onChange={(value) => {
                                    document.getElementById(item.id).value =
                                      value;
                                    setValue(`${item.id}`, value);
                                    trigger(`${item.id}`);
                                  }}
                                />
                              </label>
                            </div>
                            <div className="text-danger">
                              {errors[item.id] && errors[item.id].message}
                            </div>
                          </CardBody>
                        </Card>
                        <div className="text-danger mb-2">
                          {errors[item.id] &&
                            errors[item.id] &&
                            "Este campo es requerido"}
                        </div>
                      </>
                    )}
                  </>
                );
              })}
          </FormGroup>

          <FormGroup className="text-center mt-4 mb-5">
            <div className="d-flex align-items-center justify-content-center">
              <Button
                appearance="primary"
                type="submit"
                className="w-50"
                disabled={loadingPost}
              >
                Enviar encuesta
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};
export default FormularioEncuesta;
