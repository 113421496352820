import { useEffect } from "react";
import { Button } from "@fluentui/react-components";
import { FormularioEvaluacion } from "../../";
import useApi from "../../../../../hooks/useApi";
import { useSelector } from "react-redux";

const CalificarExperiencia = (props) => {
  const { setStep, puntosSeleccionados } = props;
  const { data: subCategories, getData } = useApi();
  const { data: departments, getData: getDataDepartments } = useApi();
  let userSession = useSelector((state) => state.authentication.userSession);
  const apiUrl = `/feedback-subcategories?categoryId=${puntosSeleccionados.id}`;
  const apiUrlDepartments = `/departments/client/${userSession.clientId}`;

  useEffect(() => {
    getData(apiUrl);
    getDataDepartments(apiUrlDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex-fill mb-3">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 2
        </p>

        <p className="font-14 font-bold text-center mb-3 ms-2 me-2 text-blue-dtmood">
          {puntosSeleccionados.name}
        </p>
        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 text-dark-dtmood">
          ¡Evalúa la categoría que desees!
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 text-blue-dtmood">
          Haz clic para ver la encuesta
        </p>

        <div className="text-left mt-4">
          {subCategories &&
            subCategories?.data.length > 0 &&
            subCategories?.data.map((item, index) => {
              return (
                <FormularioEvaluacion
                  key={index}
                  subcategory={item}
                  departments={departments}
                />
              );
            })}
        </div>

        <div className="text-left mt-4">
          <div className="d-flex align-items-center justify-content-end">
            <Button
              appearance="primary"
              type="button"
              onClick={() => setStep(1)}
            >
              Regresar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalificarExperiencia;
