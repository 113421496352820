import React, { useState } from 'react';

import styles from './FeelingsInput.module.css';
import icono1 from '../../../../../assets/images/dt-mood/encuestas/1.svg';
import icono2 from '../../../../../assets/images/dt-mood/encuestas/2.svg';
import icono3 from '../../../../../assets/images/dt-mood/encuestas/3.svg';
import icono4 from '../../../../../assets/images/dt-mood/encuestas/4.svg';
import icono5 from '../../../../../assets/images/dt-mood/encuestas/5.svg';

const icons = [
  {
    path: icono1,
    value: 1,
    selected: false,
  },
  {
    path: icono2,
    value: 2,
    selected: false,
  },
  {
    path: icono3,
    value: 3,
    selected: false,
  },
  {
    path: icono4,
    value: 4,
    selected: false,
  },
  {
    path: icono5,
    value: 5,
    selected: false,
  },
];

const FeelingsInput = ({ onChange }) => {
  const [feelings, setFeeling] = useState(icons);

  const handleFeelingClick = (feelingValue) => {
    const updatedFeelings = feelings.map((feeling) => ({
      ...feeling,
      selected: feeling.value === feelingValue,
    }));
    setFeeling(updatedFeelings);
    onChange(feelingValue);
  };

  return (
    <>
      {feelings.map((icon, i) => (
        <img
          src={icon.path}
          key={i}
          onClick={() => handleFeelingClick(icon.value)}
          alt={`Icon ${i + 1}`}
          style={{ width: '40px', height: '40px' }}
          className={`cursor-pointer ${styles.feelingIcon} ${
            icon.selected ? styles.selectedFeelingIcon : ''
          }`}
        />
      ))}
    </>
  );
};

export default FeelingsInput;
