import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Form, FormGroup, Label } from "reactstrap";
import { Card, Body1, Button, Textarea } from "@fluentui/react-components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";
import useApi from "../../../../../hooks/useApi";
import { PitazoContext } from "../../../context";
import styles from "./SeleccionarConducta.module.css";

const SeleccionarConducta = (props) => {
  const { setStep, anonymous, motivosSeleccionados } = props;
  const { setPitazoState } = useContext(PitazoContext);
  const { data, loading, error, postData } = useApi();
  const { data: subcategories, getData } = useApi();
  let userSession = useSelector((state) => state.authentication.userSession);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getData(
      `/denunciation-subcategories?categoryId=${motivosSeleccionados.id}`
    );
  }, [motivosSeleccionados]);

  useEffect(() => {
    watch((value, { name, type }) => {});
  }, [watch]);

  useEffect(() => {
    if (data) {
      if (data.badgeAchieved) {
        setPitazoState({
          state: "award",
          badge_id: data.badgeAchieved.id,
        });
      } else {
        setPitazoState({
          state: "success",
          id: data.entity.id,
        });
      }
      dispatch(ChangeMiDia(false));
    }
    if (error) {
      setPitazoState({
        state: "warning",
      });
    }
  }, [data, error]);

  const onSubmit = (data) => {
    const formData = formatData(data);
    postData(`/denunciations/`, formData);
  };

  const formatData = (data) => {
    return {
      userId: userSession.userId,
      isAnonymous: anonymous,
      comment: data.comments,
      subcategoryId: Number(data.subcategory),
    };
  };

  return (
    <>
      <div className="flex-fill mb-4">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 2
        </p>

        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 text-dark-dtmood">
          Selecciona las malas conductas <br /> relacionadas a tu motivo
        </p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-1 mt-4">
            {subcategories &&
              subcategories?.data.map((item, index) => {
                return (
                  <FormGroup inline className="w-100" key={index}>
                    <Card className="w-100 mb-2">
                      <Body1 className="pt-2 pb-2 ps-3 pe-3">
                        <div
                          className={`d-flex align-items-center ${styles.radioDiv}`}
                        >
                          <input
                            name="subcategory"
                            type="radio"
                            id={item.id}
                            value={item.id}
                            checked={item.selected}
                            {...register("subcategory", { required: true })}
                          />
                          <Label
                            htmlFor={item.id}
                            check
                            className="font-14 font-medium text-gray-dtmood ms-3"
                          >
                            {item.name}
                          </Label>
                        </div>
                      </Body1>
                    </Card>
                  </FormGroup>
                );
              })}

            {errors.subcategory && errors.subcategory.type === "required" && (
              <div className="text-danger mb-4 mt-2">
                Selecciona al menos una conducta
              </div>
            )}

            <FormGroup className="w-100">
              <Textarea
                name="comments"
                id="comments"
                cols="30"
                rows="3"
                placeholder="Puedes escribir otro motivo"
                {...register("comments", { maxLength: 300 })}
                className="mb-1 w-100"
              />
            </FormGroup>
            <div className="text-danger mt-0">
              {errors.comments &&
                errors.comments.type === "maxLength" &&
                "Máximo 300 caracteres"}
            </div>
          </div>

          <FormGroup className="text-center mt-4 mx-1">
            <div className="d-flex align-items-center justify-content-end">
              <Button
                appearance="primary"
                outline
                type="button"
                onClick={() => setStep(1)}
                className="me-2"
              >
                Regresar
              </Button>
              <Button appearance="primary" type="submit" disabled={loading}>
                Enviar pitazo
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default SeleccionarConducta;
