import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Body1,
  Card,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Table } from "reactstrap";

import astronautIcon from "../../../../assets/images/dt-mood/user-astronaut-solid.svg";
import styles from "./MisInsignias.module.css";
import { ChangeMisInsignias } from "../../store/mis-insignias/MisInsigniasSlice";
import apiDtMood from "../../services/dtMoodApiHandler";

const MisInsignias = () => {
  const isUpdatedMisInsignias = useSelector(
    (state) => state.misInsignias.isUpdatedMisInsignias
  );
  const dispatch = useDispatch();
  let userSession = useSelector((state) => state.authentication.userSession);
  const SkeletonArray = [1, 2, 3, 4, 5];

  const awards = useSelector((state) => state.awards);
  const [awardsList, setAwardsList] = useState([]);
  const [userAwardsList, setUserAwardsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    awards ? setAwardsList(awards) : setAwardsList([]);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isUpdatedMisInsignias) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatedMisInsignias]);

  const getData = async () => {
    setLoading(true);
    const promises = [getBadges(), getAwardsList()];
    const results = await Promise.allSettled(promises).finally(() => {
      setLoading(false);
    });
    const fulfilled = results.filter((p) => p.status === "fulfilled");

    if (fulfilled && fulfilled[0] && fulfilled[0].value?.data.length > 0) {
      setAwardsList(fulfilled[0].value?.data);
    }

    if (fulfilled && fulfilled[1] && fulfilled[1].value?.data.length > 0) {
      setUserAwardsList(fulfilled[1].value?.data);
      dispatch(ChangeMisInsignias(true));
    }
  };

  useEffect(() => {
    if (awardsList && userAwardsList) {
      dispatch(ChangeMisInsignias(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awardsList, userAwardsList]);

  const getObtainedAward = (badgeId) => {
    return userAwardsList.find((element) => element.badgeId === badgeId);
  };

  const getBadges = () => {
    const clientId = userSession ? userSession.clientId : "";

    return new Promise((resolve, reject) => {
      apiDtMood
        .get(`/badges/list/?clientId=${clientId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getAwardsList = () => {
    const userId = userSession ? userSession.userId : "";

    return new Promise((resolve, reject) => {
      apiDtMood
        .get(`/awards/list/?userId=${userId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <Card className="w-100 p-0">
      <div className="p-3 border-bottom mb-0">
        <div className="container text-start">
          <div className="row align-items-center">
            <div className="col">
              <img
                src={astronautIcon}
                alt="icono-emociones"
                className={`align-self-center ${styles.titleIcon}`}
              />
              <span className="align-self-center ms-2">Mis insignias</span>
            </div>
          </div>
        </div>
      </div>
      <Body1 className="p-3">
        <div className="flex-fill">
          <p className="font-16">¡Actívate y sube de nivel!</p>

          <Table
            hover
            borderless
            className={`font-14 ${styles.tablaInsignias}`}
          >
            <thead>
              <tr className="border-bottom">
                <th className="font-medium align-middle">&nbsp;</th>
                <th className="font-medium align-middle">&nbsp;</th>
                <th className="font-medium align-middle">Nivel</th>
                <th className="font-medium align-middle">Interacciones</th>
                <th className="font-medium align-middle">Mis logros</th>
              </tr>
            </thead>

            {loading &&
              SkeletonArray.map((item, index) => {
                return (
                  <tr className="border-bottom" key={index}>
                    <td className="align-middle" colSpan={5}>
                      <Skeleton>
                        <SkeletonItem size={16} className="my-2" />
                      </Skeleton>
                    </td>
                  </tr>
                );
              })}

            {awardsList && userAwardsList && (
              <tbody>
                {awardsList.map((item, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="align-middle">
                        <img
                          src={item.imagePath}
                          alt={item.name}
                          className={`${styles.insigniaTabla} ${
                            !getObtainedAward(item.badgeId) ? "grey-scale" : ""
                          }`}
                        />
                      </td>
                      <td className="font-bold align-middle">{index + 1}</td>
                      <td className="font-bold align-middle">{item.badge}</td>
                      <td className="font-medium align-middle">
                        {item.interactionsTarget} interacciones
                      </td>
                      <td className="font-medium align-middle">
                        <span
                          className={`${styles.badgeDtmood} ${
                            getObtainedAward(item.badgeId)
                              ? styles.badgeDtmoodWon
                              : styles.badgeDtmoodPending
                          }`}
                        >{`${
                          getObtainedAward(item.badgeId)
                            ? "Ganaste"
                            : "Pendiente"
                        }`}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        </div>
      </Body1>
    </Card>
  );
};

export default MisInsignias;
