import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Body1,
  Card,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Col, Row } from "reactstrap";

import useApi from "../../../../hooks/useApi";
import style from "./FraseDelDia.module.css";

const FraseDelDia = () => {
  const [fraseDelDia, setFraseDelDia] = useState();
  const { data, loading, error, getData } = useApi();
  const phrase = "¡Motívate y logra tus objetivos!";
  const userSession = useSelector((state) => state.authentication.userSession);
  const apiUrl = userSession
    ? `/phrases/today?clientId=${userSession.clientId}&groupId=${userSession.groupId}`
    : "";

  useEffect(() => {
    getData(apiUrl);
  }, []);

  useEffect(() => {
    if (data) {
      const respPhrase = data.data !== "" ? data.data.phrase : phrase;
      setFraseDelDia(respPhrase);
    }
    if (error) {
      setFraseDelDia(phrase);
    }
  }, [data, error]);

  return (
    <Card className={`w-100 ${style.borderDtmood}`}>
      <Body1>
        <div className="flex-fill">
          <Row>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              className="align-self-center font-16"
            >
              <p className="text-color-dtmood font-medium">Tu frase del día</p>
              {loading && (
                <Skeleton>
                  <SkeletonItem size={16} className="my-2" />
                </Skeleton>
              )}
              {fraseDelDia && !loading && (
                <p className="font-medium">{fraseDelDia}</p>
              )}
            </Col>
          </Row>
        </div>
      </Body1>
    </Card>
  );
};

export default FraseDelDia;
