import React, { useState } from "react";

import styles from "./RatingInput.module.css";
import { CheckmarkFilled } from "@fluentui/react-icons";

const icons = [
  {
    value: 1,
    selected: false,
  },
  {
    value: 2,
    selected: false,
  },
  {
    value: 3,
    selected: false,
  },
  {
    value: 4,
    selected: false,
  },
  {
    value: 5,
    selected: false,
  },
];

const RatingInput = ({ onChange }) => {
  const [calificaciones, setCalificaciones] = useState(icons);

  const handleClick = (value) => {
    const updatedCalificaciones = calificaciones.map((calificacion) => ({
      ...calificacion,
      selected: calificacion.value === value,
    }));
    setCalificaciones(updatedCalificaciones);
    onChange(value);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-center mb-3">
        {calificaciones &&
          calificaciones.map((item, index) => {
            return (
              <div
                key={index}
                className={`d-flex justify-content-center align-items-start me-1 cursor-pointer ${
                  item.selected ? styles.valueCardSelected : styles.valueCard
                }`}
                onClick={() => handleClick(index + 1)}
              >
                {item.selected && (
                  <div
                    className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                  >
                    <CheckmarkFilled className={styles.checkIcon} />
                  </div>
                )}
                {!item.selected && (
                  <div
                    className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                  >
                    {item.value}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RatingInput;
