import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Body1,
  Dialog,
  DialogSurface,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogBody,
  Button,
  Skeleton,
  SkeletonItem,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Col, Row } from "reactstrap";
import iconoDtMood from "../../../../../assets/images/dt-mood/icono-dtmood.png";
import iconoCalendar from "../../../../../assets/images/dt-mood/encuestas/calendar.svg";
import styles from "./InformacionUsuario.module.css";
import { FormularioEncuesta } from "../../";
import { EncuestaContext } from "../../../context";
import {
  showFeedbackSuccessAlert,
  showFeedbackWarningAlert,
} from "../../../utilities";
import { ChangeAwardStatus } from "../../../store/award/AwardSlice";
import useApi from "../../../../../hooks/useApi";

const InformacionUsuario = (props) => {
  const { setReloadMiDia } = props;
  const { data: encuesta, loading, error, getData } = useApi();
  const [encuestaState, setEncuestaState] = useState();
  const [open, setOpen] = useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const dispatch = useDispatch();
  let userSession = useSelector((state) => state.authentication.userSession);
  const apiUrl = `/survey-results/list?userId=${userSession.userId}`;

  useEffect(() => {
    getData(apiUrl);
  }, []);

  useEffect(() => {
    setOpen(false);

    if (encuestaState && encuestaState.state === "award") {
      dispatch(ChangeAwardStatus(encuestaState.badge_id));
      setEncuestaState();
      getData(apiUrl);
      setReloadMiDia(true);
    }

    if (encuestaState && encuestaState.state === "success") {
      showFeedbackSuccessAlert(encuestaState.id);
      setEncuestaState();
      getData(apiUrl);
      setReloadMiDia(true);
    }

    if (encuestaState && encuestaState.state === "warning") {
      showFeedbackWarningAlert();
      setEncuestaState();
    }
  }, [encuestaState]);

  return (
    <>
      <Card className="w-100">
        <Body1 className="h-100">
          <div className="flex-fill h-100">
            {loading && (
              <Row className="h-100">
                <Col xs="12">
                  <Skeleton>
                    <SkeletonItem
                      size={16}
                      className={`my-2 ${styles.surveyTitleSkeleton}`}
                    />
                  </Skeleton>
                </Col>
                <Col xs="12">
                  <Skeleton>
                    <SkeletonItem size={16} className="my-2" />
                  </Skeleton>
                </Col>
              </Row>
            )}
            {(!encuesta || encuesta.data.length === 0 || error) && !loading && (
              <Row className="h-100">
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="1"
                  className="d-flex align-items-center"
                >
                  <img
                    src={iconoDtMood}
                    alt="dtmoo-icon"
                    className={styles.iconDtmood}
                  />
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="9"
                  lg="11"
                  className="d-flex align-items-center"
                >
                  <div className="flex-fill mt-3">
                    <h3>
                      ¿{userSession.first_name}, cómo te sentiste el día de hoy?
                    </h3>
                    <p>
                      Gana insignias y sube de nivel, interactuando diariamente.
                    </p>
                  </div>
                </Col>
              </Row>
            )}
            {encuesta && encuesta.data.length > 0 && !loading && (
              <Row className="h-100 my-2">
                <Col
                  xs="12"
                  sm="2"
                  md="2"
                  lg="1"
                  className="d-flex align-items-center"
                >
                  <img
                    src={iconoCalendar}
                    alt="dtmoo-icon"
                    className={styles.iconDtmood}
                  />
                </Col>
                <Col
                  xs="12"
                  sm="10"
                  md="10"
                  lg="11"
                  className="d-flex align-items-stretch"
                >
                  <div className="d-flex justify-content-between ps-2 w-100">
                    <div className="w-100">
                      <div className="w-100">
                        <h3 className={`font-medium ${styles.surveyTitle}`}>
                          {
                            encuesta.data.filter(
                              (item) => item.status === false
                            )[0].survey.title
                          }
                        </h3>
                      </div>
                      <div className="w-100 d-flex justify-content-between">
                        <p
                          className={`font-16 mb-0 m-auto p-1 ${styles.surveyDescription}`}
                        >
                          Por favor, tómate un momento para responder esta breve
                          encuesta. ¡Estamos emocionados de tenerte aquí!
                        </p>
                        <Button
                          appearance="primary"
                          className={`${styles.button}`}
                          onClick={() => setOpen(true)}
                        >
                          Responder
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Body1>
      </Card>

      {encuesta && (
        <Dialog
          open={open}
          onOpenChange={(event, data) => {
            setOpen(data.open);
          }}
          onDismiss={{ ...restoreFocusTargetAttribute }}
        >
          <DialogSurface className={`p-1 ${styles.encuestasBg}`}>
            {open && (
              <>
                <DialogBody className={`${styles.dialogBody}`}>
                  <div className={`p-3 ${styles.titleDiv}`}>
                    <DialogTitle
                      action={
                        <DialogTrigger action="close">
                          <Button
                            appearance="subtle"
                            aria-label="close"
                            icon={<Dismiss24Regular />}
                          />
                        </DialogTrigger>
                      }
                    >
                      <span className="text-color-headings font-medium">
                        {
                          encuesta.data.filter(
                            (item) => item.status === false
                          )[0].survey.title
                        }
                      </span>
                    </DialogTitle>
                  </div>

                  <DialogContent className={`p-3 mt-1 ${styles.dialogContent}`}>
                    <EncuestaContext.Provider value={{ setEncuestaState }}>
                      <FormularioEncuesta
                        survey={
                          encuesta.data.filter(
                            (item) => item.status === false
                          )[0]
                        }
                      />
                    </EncuestaContext.Provider>
                  </DialogContent>
                </DialogBody>
              </>
            )}
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
};

export default InformacionUsuario;
