import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Textarea } from "@fluentui/react-components";
import { FormGroup } from "reactstrap";

import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";
import { EmocionesContext } from "../../../context";
import useApi from "../../../../../hooks/useApi";
import EmojioneV4 from "react-emoji-render";

const VerificarRespuesta = (props) => {
  const { setStep, emocionesSeleccionadas, motivosSeleccionados, setModal } =
    props;
  const { setEmocionesState } = useContext(EmocionesContext);
  const { data, loading, error, postData } = useApi();
  let userSession = useSelector((state) => state.authentication.userSession);
  const userId = userSession ? userSession.userId : "";
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      if (data.badgeAchieved) {
        setEmocionesState({
          state: "award",
          badge_id: data.badgeAchieved.id,
        });
      } else {
        setEmocionesState({
          state: "success",
        });
      }
      dispatch(ChangeMiDia(false));
    }
    if (error) {
      setEmocionesState({
        state: "warning",
      });
    }
    setModal(false);
  }, [data, error]);

  const onSubmit = () => {
    const formData = formatData();
    postData(`/feelings/`, formData);
  };

  const formatData = () => {
    const totalEmociones = emocionesSeleccionadas.map((item) => {
      return item.id;
    });

    return {
      userId: userId,
      typesIds: totalEmociones,
      comments: motivosSeleccionados.comments,
    };
  };

  return (
    <>
      <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
        Resumen
      </p>

      <p className="font-16 font-medium text-center text-color-dark-blue">
        Estas son tus emociones del día
      </p>

      <div className="d-flex justify-content-center">
        {emocionesSeleccionadas &&
          emocionesSeleccionadas.map((item, index) => {
            return (
              <div
                className="flex-fill justify-content-center m-2"
                key={index}
                style={{ maxWidth: "110px" }}
              >
                <div className="d-flex justify-content-center">
                  <EmojioneV4
                    text={item.emojiCode}
                    className="icon-card-emociones"
                  />
                </div>
                <div className="w-100 mt-2">
                  <p className="text-center font-medium text-color-dark-blue-2 font-12 m-0 p-0">
                    {item.name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>

      <div className="m-3 mx-1 mb-4">
        {motivosSeleccionados.comments && (
          <FormGroup className="w-100">
            <Textarea
              name="comments"
              id="comments"
              cols="30"
              rows="4"
              placeholder="Puedes escribir otro motivo"
              className="mb-1 w-100"
              value={motivosSeleccionados.comments}
              readOnly
            />
          </FormGroup>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-end mb-3">
        <Button
          appearance="primary"
          outline
          type="button"
          className="me-2"
          onClick={() => setStep(1)}
        >
          Regresar
        </Button>
        <Button
          appearance="primary"
          type="button"
          disabled={loading}
          onClick={onSubmit}
        >
          Finalizar
        </Button>
      </div>
    </>
  );
};

export default VerificarRespuesta;
