import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Body1,
  Dialog,
  DialogSurface,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogBody,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Row, Col } from "reactstrap";

import styles from "./SeleccionEmociones.module.css";
import iconoEmociones from "../../../../../assets/images/dt-mood/emociones/icono-emociones.png";
import MisEmocionesDelDia from "../mis-emociones-del-dia/MisEmocionesDelDia";
import { EmocionesContext } from "../../../context";
import {
  showEmocionesSuccessAlert,
  showEmocionesWarningAlert,
} from "../../../utilities";
import { ChangeAwardStatus } from "../../../store/award/AwardSlice";
import { ChangePuntosAcumulados } from "../../../store/puntos-acumulados/PuntosAcumuladosSlice";
import EmojioneV4 from "react-emoji-render";
import useApi from "../../../../../hooks/useApi";

const SeleccionEmociones = (props) => {
  const { data: emociones, getData } = useApi();
  const [clicked, setClicked] = useState(0);
  const [emocionesSeleccionadas, setEmocionesSeleccionadas] = useState(0);
  const allowed = 4;
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const [emocionesState, setEmocionesState] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getData("/feeling-types/");

    return () => {
      cleanSelection();
      setEmocionesState();
    };
  }, []);

  useEffect(() => {
    if (!open) cleanSelection();
  }, [open]);

  useEffect(() => {
    setOpen(false);

    if (emocionesState && emocionesState.state === "award") {
      dispatch(ChangeAwardStatus(emocionesState.badge_id));
      dispatch(ChangePuntosAcumulados(false));
      setEmocionesState();
    }

    if (emocionesState && emocionesState.state === "success") {
      dispatch(ChangePuntosAcumulados(false));
      showEmocionesSuccessAlert();
      setEmocionesState();
    }

    if (emocionesState && emocionesState.state === "warning") {
      showEmocionesWarningAlert();
      setEmocionesState();
    }
  }, [emocionesState]);

  const handleClick = (index) => {
    if (emociones.data[index] && emociones.data[index].selected) {
      emociones.data[index].selected = false;
      setClicked(clicked + 1);
      return;
    }

    if (!emociones.data[index] || emocionesSeleccionadas.length >= allowed) {
      return;
    }
    emociones.data[index].selected = !emociones.data[index].selected;
    setClicked(clicked + 1);
  };

  useEffect(() => {
    const total =
      emociones && emociones.data.length > 0
        ? emociones.data.filter((item) => item.selected)
        : 0;
    setEmocionesSeleccionadas(total);
  }, [clicked]);

  const cleanSelection = () => {
    if (emociones && emociones.data.length > 0) {
      emociones.data.map((item) => (item.selected = false));
      setEmocionesSeleccionadas([]);
    }
  };

  return (
    <>
      <Card className="w-100 p-0">
        <div className="p-3 border-bottom mb-0">
          <div className="container text-start">
            <div className="row align-items-center">
              <div className="col">
                <img
                  src={iconoEmociones}
                  alt="icono-emociones"
                  className={`align-self-center ${styles.titleIcon}`}
                />
                <span className="align-self-center ps-2">
                  Mis emociones del día
                </span>
              </div>
            </div>
          </div>
        </div>
        <Body1 className="p-3">
          <div className="flex-fill">
            <p className="font-14 font-medium text-center">
              Selecciona de 1 a 4 emociones haciendo clic en las caritas
            </p>

            <Row>
              {emociones &&
                emociones.data.map((item, index) => {
                  return (
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="3"
                      xxl="3"
                      className="d-flex mt-3"
                      key={index}
                    >
                      <Card
                        className={`w-100 ${
                          item.selected ? styles.emocionSeleccionada : ""
                        }`}
                        onClick={() => handleClick(index)}
                      >
                        <Body1 className="ps-1 pe-1 cursor-pointer">
                          <div className="w-100 flex-fill">
                            <div className="d-flex justify-content-center">
                              <EmojioneV4
                                text={item.emojiCode}
                                className="icon-card-emociones"
                              />
                            </div>
                            <div className="w-100 mt-2">
                              <p className="text-center font-12 m-0 p-0">
                                {item.name}
                              </p>
                            </div>
                          </div>
                        </Body1>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn btn-dt-mood mb-2"
              appearance="primary"
              type="button"
              onClick={toggle}
              disabled={emocionesSeleccionadas.length === 0}
            >
              Registrar mis emociones
            </Button>
          </div>
        </Body1>
      </Card>

      <Dialog
        open={open}
        onOpenChange={(event, data) => {
          setOpen(data.open);
        }}
        onDismiss={{ ...restoreFocusTargetAttribute }}
      >
        <DialogSurface className={`p-1 ${styles.modalHeaderBg}`}>
          {open && (
            <>
              <DialogBody className={`${styles.dialogBody}`}>
                <div className={`p-3 ${styles.titleDiv}`}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <img
                      src={iconoEmociones}
                      alt="icono-emociones"
                      className={`align-self-center me-2 pt-2 ${styles.icono}`}
                    />
                    <span className="text-color-headings font-medium">
                      Mis emociones del día
                    </span>
                  </DialogTitle>
                </div>

                <DialogContent className={`p-3 mt-1 ${styles.dialogContent}`}>
                  <EmocionesContext.Provider
                    value={{ emocionesState, setEmocionesState }}
                  >
                    <MisEmocionesDelDia
                      emocionesSeleccionadas={emocionesSeleccionadas}
                      setModal={() => setOpen}
                    />
                  </EmocionesContext.Provider>
                </DialogContent>
              </DialogBody>
            </>
          )}
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default SeleccionEmociones;
